<template>
  <div class="flex flex-col">
    <span
      class="-mt-10 font-extrabold text-blue-500 
      items-center inline-flex cursor-pointer"
      @click="$emit('goBack', 'select-branch')"
    >
      <img :src="assets.icons.sm.back" class="mr-2" alt="Back" />
      Back
    </span>
    <h1 class="text-34 mt-2 font-black tracking-tight mb-2">
      {{ businessName | sentenceCase }}
    </h1>
    <p class="text-12 font-normal mb-4 text-marketplace-blue text-opacity-75">
      Flexible payment for purchases at {{ businessName | sentenceCase }}.
    </p>

    <div
      class="border border-gray-300 rounded w-full min-h-48px 
      cursor-pointer flex justify-between items-center p-3 mt-3"
    >
      <div class="inline-flex">
        <ion-icon
          name="location-outline"
          class="text-base font-black text-deal"
        ></ion-icon>
        <div class="flex flex-col ml-3 text-deal">
          <h5 class="font-bold text-11 sm:text-12">{{ branchName }}</h5>
          <p class="font-thin text-10 sm:text-12">{{ branchLocation }}</p>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-2 sm:gap-1">
        <div class="col-span-1 contact" @click="callBranch">
          <ion-icon name="call-outline" class="text-white text-lg"></ion-icon>
        </div>
        <div class="col-span-1 contact" @click="navigateToWebsite">
          <ion-icon name="globe-outline" class="text-white text-lg"></ion-icon>
        </div>
        <div class="col-span-1 contact">
          <ion-icon name="logo-whatsapp" class="text-white text-lg"></ion-icon>
        </div>
      </div>
    </div>

    <div class="mt-12">
      <h1
        class="text-12 text-deal text-opacity-75 font-bold tracking-tight mb-4"
      >
        Product info
      </h1>
      <form-group
        name="item"
        :form="form"
        :data="form.data.item"
        v-model="form.data.item.value"
      >
        Order Description
      </form-group>
      <form-group
        type="money"
        name="amount"
        :form="form"
        :data="form.data.amount"
        v-model="form.data.amount.value"
      >
        Amount
      </form-group>
    </div>

    <div class="mt-20 flex justify-center">
      <button
        class="btn btn-blue py-6 w-full"
        type="button"
        :disabled="isInputedAmountEmpty"
        @click="proceed"
      >
        Continue
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selectedBranch: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: this.$options.basicForm([
        { name: "item", rules: "required|aplpha" },
        { name: "amount", rules: "required" }
      ])
    };
  },
  computed: {
    vendor() {
      return this.selectedBranch?.vendor;
    },
    website() {
      return this.vendor?.website;
    },
    businessName() {
      return this.vendor?.business_name;
    },
    isInputedAmountEmpty() {
      return this.form.data.amount.value <= 0;
    },
    branchName() {
      return this.selectedBranch?.name;
    },
    branchLocation() {
      return this.selectedBranch?.address;
    },
    branchContact() {
      return this.selectedBranch?.phone || "No phone number provided";
    }
  },
  methods: {
    navigateToWebsite() {
      window.open(`https://${this.website}`, "_blank", "noopenner");
    },
    callBranch() {
      window.open(`tel:${this.branchContact}`, "_blank");
    },
    proceed() {
      if (!this.validateForm(this.form)) {
        return false;
      }
      this.$emit("setOrderDetails", this.form.data);
      this.$emit("proceed", "payment-method");
    }
  }
};
</script>
<style lang="scss" scoped>
.contact {
  @apply rounded;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply cursor-pointer;
  @apply bg-deal;
  @apply w-8;
  @apply h-8;
}
</style>
